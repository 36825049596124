import { useState } from "react";
import logo from "./logo-crop.png";
import "./App.css";

function App() {
  const url = "https://ecodim.apgmb.fr";
  const [countdown, setCountdown] = useState(10);

  const count = setInterval(() => {
    if (countdown === 0) {
      stopInterval();
      setTimeout(() => {
        window.location = url;
      }, 500);
    } else {
      setCountdown(countdown - 1);
    }
  }, 1000);

  const stopInterval = () => {
    clearInterval(count);
  };

  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          alt="logo"
          style={{ maxHeight: "100%", maxWidth: "100%" }}
        />
        <p>
          Suite à la mise en place de la nouvelle plateforme <b>Ecodim</b>, les
          services GEM ont été désactivés.
          <br />
          <br />
          {countdown > 0 && (
            <>
              Vous serez automatiquement redirigé dans {countdown} seconde
              {countdown > 1 ? "s" : ""}
            </>
          )}
          {countdown === 0 && "Redirection..."}
        </p>
        <a
          className="App-link"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          Accéder à Ecodim
        </a>
      </header>
    </div>
  );
}

export default App;
